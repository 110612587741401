import styled, { css } from 'styled-components/macro';
import { Separator } from '@snsw/react-component-library/build/Accordion/Separator.styled';
import { LabelContainer } from '@snsw/react-component-library/build/Accordion/Label.styled';
import { colors } from '@snsw/react-component-library/build/GlobalStyle/colors/colors';

export const AccordionStyled = styled.div`
  ${props => {
        const { border } = props;
        return border
            ? css`
      border-radius: 6px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    `
            : null;
    }};
  background-color: white;
`;

export const TileBody = styled.div`
    padding: 0 1.5rem 1.5rem 1.5rem;
`;

export const AccordionTileContainer = styled.div`
  position: relative;
  padding: 1.5rem;
  :focus {outline: 0}
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  
  ${Separator} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
   
  ${
    props => props.open
        ? css`padding: 0;`
        : null
};
  
  ${
    props => {
        if (props.separator === undefined) {
            return css`
              :not(:first-child) {
                border-top: 1px solid #dee3e5;
              }
              :last-child {
                border-bottom: 1px solid #dee3e5;
              }
            `;
        } else if (props.separator) {
            return css`
              border-top: 1px solid #dee3e5;
            `;
        }
    }};
   
  .progress {
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    left: 0;
    
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
  }
  
  .top {
    width: 100%;
    height: 38px;
  }
  
  .bottom {
    width: 100%;
    height: 100%;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: baseline;
  user-select: none;
  outline: none;
  font: normal normal 24px Public Sans,Arial, Helvetica, sans-serif;
  font-stretch: normal;
  letter-spacing: normal;
  h5 {
      margin-right: 1.4375rem;
  }
  
  ${props => {
        const { open } = props;
        return open
            ? css`
      padding:1.5rem 1.5rem;
      background-color: #e2e6e8;
      border-radius: 5px;
      opacity: 0.9;    
      `
            : null;
    }}

  ${LabelContainer} {
    position: absolute;
    left: 0;
  }
  
  ${props => props.stepAccordionSeparator
        ? css`
      :before {
        content: '';
        display: block;
        width: calc(100% - 3.5rem);
        border-top: ${colors.lightGrey2} solid 1px;
        position: absolute;
        top: 0;
      }
    `
        : null}
`;

export const AccordionSubTitle = styled('h6')`
    margin: 0;
    font-family: Public Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    color: #242934;
    font-weight: 500;
    font-size: 1.1rem;
`;

export const ToggleIcon = styled.div`
  padding-top: 5px;
  transition: transform .4s ease-in-out;
  transform: ${props => {
        const { rotateIt } = props;
        return rotateIt ? 'rotate(180deg)' : 'none';
    }};
`;

export const AccordionBody = styled('div')`
  padding: 1rem 0 0 0;
  ul, ol {
    margin-top: 0;
    margin-bottom: 0;
    li {
      padding: 0.4rem 0;
    }
  }
`;
