import { createGlobalStyle } from 'styled-components';

const KiamaGlobalFont = createGlobalStyle`
    html {
        font-family: "Public Sans", "Public Sans", "Helvetica", "Arial", sans-serif !important;
    }
    body {
        margin: 0px;
    }
    a {
        text-decoration: underline;
    }
`;

export default KiamaGlobalFont;
